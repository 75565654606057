<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="module">
    <div class="m_left">
      <div class="page-title">院系/专业/班级</div>
      <sy-tree @nodeclick="select"></sy-tree>
    </div>
    <div ref="m_right" class="m_right">
      <div class="page-title">新生信息</div>
      <!--==============================工具==================================-->
      <div class="page-tool">
        <div>
          <span class="mr5 fcolor42">是否报道:</span>
          <el-select v-model="isbd" size="small" clearable>
            <el-option label="已报到" value="已报到"></el-option>
            <el-option label="未报到" value="未报到"></el-option>
          </el-select>
          <span class="mr5 ml10 fcolor42">是否缴费:</span>
          <el-select v-model="isjf" size="small" clearable>
            <el-option label="已缴费" value="已缴费"></el-option>
            <el-option label="未缴费" value="未缴费"></el-option>
          </el-select>
          <el-input
            v-model="keyword"
            placeholder="请输入学号/姓名/考生号"
            size="small"
            style="width: 200px; margin-left: 10px"
          ></el-input>
          <el-button
            class="ml10"
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getDataPageList"
            >搜索</el-button
          >
        </div>

        <el-button-group>
          <el-button
            size="small"
            icon="el-icon-refresh"
            @click="reload()"
          ></el-button>
          <!--<el-button size="small" icon="el-icon-box" @click="look"
            >查看</el-button
          >-->
          <el-button size="small" icon="el-icon-download" @click="showExport"
            >导出</el-button
          >
        </el-button-group>
      </div>
      <!--=============================表格========================================-->
      <div class="c_table">
        <el-table
          v-loading="tableloading"
          :data="dataPageList"
          :height="windowHeight - 270"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <el-table-column prop="ksh" label="考生号" width="200" fixed>
          </el-table-column>
          <el-table-column prop="xh" label="学号" width="150" fixed>
          </el-table-column>
          <el-table-column prop="xm" label="姓名" width="100" fixed>
          </el-table-column>
          <el-table-column prop="xbm" label="性别" width="100">
            <template slot-scope="scope">
              {{ scope.row.xbm == 1 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop="bmmc" label="院系" width="200">
          </el-table-column>
          <el-table-column prop="zymc" label="专业" width="200">
          </el-table-column>
          <el-table-column prop="bjmc" label="班级" width="200">
          </el-table-column>
          <el-table-column prop="nj" label="年级" width="100">
          </el-table-column>
          <el-table-column prop="lxdh" label="联系电话" width="200">
          </el-table-column>
          <el-table-column prop="fjbh" label="房间号" width="100">
          </el-table-column>

          <el-table-column prop="isbd" label="是否报道">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isbd == '已报到'" type="success"
                >已报到</el-tag
              >
              <el-tag v-if="scope.row.isbd == '未报到'" type="danger"
                >未报到</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="isjf" label="是否缴费">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isjf == '已缴费'" type="success"
                >已缴费</el-tag
              >
              <el-tag v-if="scope.row.isjf == '未缴费'" type="danger"
                >未缴费</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
        <!--=======================================分页================================================-->
        <div class="pagebox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination.records"
          >
          </el-pagination>
        </div>
      </div>
      <!--导出日志弹出框-->
      <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
        <div>
          <el-checkbox
            v-model="excelForm.KSH"
            label="考生号"
            border
            style="width: 130px; margin-top: 10px; margin-left: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XH"
            label="学号"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XM"
            label="姓名"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XB"
            label="性别"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.BMMC"
            label="院系"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ZYMC"
            label="专业"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.NJ"
            label="年级"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.LXDH"
            label="联系电话"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.FJBH"
            label="房间号"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ISBD"
            label="是否报道"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ISJF"
            label="是否缴费"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogExcelVisible = false">取 消</el-button>
          <el-button type="primary" @click="exportToExcel">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import bmtree from '../../components/tree/bm_zy_bj.vue'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { getNewStudentPageList, exportNewStudentExcel } from '../../api/yingxin'
export default {
  components: {
    'sy-tree': bmtree,
  },
  data() {
    return {
      buttonauth: [],
      tableloading: false,
      dialogExcelVisible: false,
      dataPageList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      keyword: '',
      isbd: null,
      isjf: null,
      bmbh: '',
      zybh: '',
      bjbh: '',
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      excelForm: {
        KSH: true,
        XH: true,
        XM: true,
        XB: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        NJ: true,
        LXDH: true,
        FJBH: true,
        ISBD: true,
        ISJF: true,
      },
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    select(node) {
      this.bmbh = ''
      this.zybh = ''
      this.bjbh = ''
      if (node.type === 'bm') {
        this.bmbh = node.value
      } else if (node.type === 'zy') {
        this.zybh = node.value
      } else if (node.type === 'bj') {
        this.bjbh = node.value
      }
      this.getDataPageList()
    },
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getDataPageList() {
      this.tableloading = true
      getNewStudentPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          isbd: this.isbd,
          isjf: this.isjf,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    look() {},
    reload() {
      this.$router.go(0)
    },
    showLook(id) {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.KSH) {
        exportFiles.push({ name: 'KSH', label: '考生号' })
      }
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.XB) {
        exportFiles.push({ name: 'XB', label: '性别' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.NJ) {
        exportFiles.push({ name: 'NJ', label: '年级' })
      }
      if (this.excelForm.LXDH) {
        exportFiles.push({ name: 'LXDH', label: '联系电话' })
      }
      if (this.excelForm.FJBH) {
        exportFiles.push({ name: 'FJBH', label: '房间编号' })
      }
      if (this.excelForm.ISBD) {
        exportFiles.push({ name: 'ISBD', label: '是否报道' })
      }
      if (this.excelForm.ISJF) {
        exportFiles.push({ name: 'ISJF', label: '是否缴费' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportNewStudentExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          isbd: this.isbd,
          isjf: this.isjf,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
        }),
        fileName: '新生信息',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.module {
  position: relative;
  padding-left: 210px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.m_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
}
.m_right {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
